import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import HomeLayout from "../layouts/home/Index.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: HomeLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/home/Index.vue")
      },
      {
        path: "hakkimizda",
        name: "About",
        component: () => import("../views/about-us/Index.vue")
      },
      {
        path: "egitim-katalogu",
        component: () => import("../layouts/course-catalog/Index.vue"),
        children: [
          {
            path: "",
            name: "CourseCatalog",
            component: () => import("../views/course-catalog/Index.vue")
          },
          {
            path: ":urlCode",
            name: "CourseCatalogDetail",
            component: () => import("../views/course-catalog/Detail.vue")
          }
        ]
      },
      {
        path: "program-katalogu",
        component: () => import("../layouts/program-catalog/Index.vue"),
        children: [
          {
            path: "",
            name: "ProgramCatalog",
            component: () => import("../views/program-catalog/Index.vue")
          },
          {
            path: "hegem-siddeti-onleme-ve-insan-haklari-danismanligi",
            name: "SiddediOnlemeVeInsanHaklariDanismanligi",
            component: () => import("../views/program-catalog/SiddediOnlemeVeInsanHaklariDanismanligi.vue")
          },
          {
            path: "hegem-yeminli-sosyal-arabuluculuk",
            name: "YeminliSosyalArabuluculuk",
            component: () => import("../views/program-catalog/YeminliSosyalArabuluculuk.vue")
          },
          {
            path: "kooperatifcilik",
            name: "Kooperatifcilik",
            component: () => import("../views/program-catalog/Kooperatifcilik.vue")
          }
          // ,{
          //   path: "yerel-yonetimler",
          //   name: "YerelYonetimler",
          //   component: () => import("../views/program-catalog/YerelYonetimler.vue")
          // },
          // {
          //   path: "muzik-ve-bale-ortaokulu",
          //   name: "MuzikVeBale",
          //   component: () => import("../views/program-catalog/MuzikVeBale.vue")
          // },
          // {
          //   path: "koro",
          //   name: "Koro",
          //   component: () => import("../views/program-catalog/Koro.vue")
          // },
          // {
          //   path: "ingilizce-hazirlik",
          //   name: "IngilizceHazirlik",
          //   component: () => import("../views/program-catalog/IngilizceHazirlik.vue")
          // }
        ]
      },
      {
        path: "sepetim",
        name: "MyCart",
        component: () => import("../views/shopping/MyCart.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "basvuru/:programUrlCode",
        name: "Apply",
        component: () => import("../views/program-catalog/Apply.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "kullanim-sartlari",
        name: "TermsOfUse",
        component: () => import("../views/TermsOfUse.vue")
      },
      {
        path: "gizlilik-politikasi",
        name: "PrivacyPolicy",
        component: () => import("../views/PrivacyPolicy.vue")
      },
      {
        path: "kisisel-verilerin-korunmasi",
        name: "PersonalDataProtection",
        component: () => import("../views/PersonalDataProtection.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/account/Index.vue"),
    children: [
      {
        path: "hesap-olustur",
        name: "Register",
        component: () => import("../views/account/Register.vue")
      },
      {
        path: "giris",
        name: "Login",
        component: () => import("../views/account/Login.vue")
      },
      {
        path: "parolami-unuttum",
        name: "ForgotPassword",
        component: () => import("../views/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../layouts/admin/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "AdminHome",
        //component: () => import("../views/admin/Index.vue"),
        redirect: { name: "AdminCourses" }
      },
      {
        path: "egitimler",
        name: "AdminCourses",
        component: () => import("../views/admin/courses/Index.vue")
      },
      {
        path: "egitimler/olustur",
        name: "AdminCourseCreate",
        component: () => import("../views/admin/courses/Create.vue")
      },
      {
        path: "egitimler/:courseId/duzenle",
        name: "AdminCourseEdit",
        component: () => import("../views/admin/courses/Edit.vue")
      },
      {
        path: "kullanicilar",
        name: "AdminUsers",
        component: () => import("../views/admin/users/Index.vue")
      },
      {
        path: "kullanicilar/olustur",
        name: "AdminUserCreate",
        component: () => import("../views/admin/users/Create.vue")
      },
      {
        path: "kullanicilar/:userId/duzenle",
        name: "AdminUserEdit",
        component: () => import("../views/admin/users/Edit.vue")
      },
      {
        path: "faaliyet-gruplari",
        name: "AdminActivityGroups",
        component: () => import("../views/admin/activity-groups/Index.vue")
      },
      {
        path: "faaliyet-gruplari/olustur",
        name: "AdminActivityGroupCreate",
        component: () => import("../views/admin/activity-groups/Create.vue")
      },
      {
        path: "faaliyet-gruplari/:id/duzenle",
        name: "AdminActivityGroupEdit",
        component: () => import("../views/admin/activity-groups/Edit.vue")
      },
      {
        path: "faaliyetler",
        name: "AdminActivities",
        component: () => import("../views/admin/activities/Index.vue")
      },
      {
        path: "faaliyetler/olustur",
        name: "AdminActivityCreate",
        component: () => import("../views/admin/activities/Create.vue")
      },
      {
        path: "faaliyetler/:id/duzenle",
        name: "AdminActivityEdit",
        component: () => import("../views/admin/activities/Edit.vue")
      },
      {
        path: "gorevler",
        name: "AdminTasks",
        component: () => import("../views/admin/tasks/Index.vue")
      },
      {
        path: "gorevler/olustur",
        name: "AdminTaskCreate",
        component: () => import("../views/admin/tasks/Create.vue")
      },
      {
        path: "gorevler/:id/duzenle",
        name: "AdminTaskEdit",
        component: () => import("../views/admin/tasks/Edit.vue")
      },
      {
        path: "gorevler/:id",
        name: "AdminTaskDetails",
        component: () => import("../views/admin/tasks/Details.vue")
      }
    ]
  },
  {
    path: "/kullanici",
    component: () => import("../layouts/user/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "egitimlerim",
        name: "MyCourses",
        component: () => import("../views/user/MyCourses.vue")
      },
      {
        path: "siparislerim",
        name: "MyOrders",
        component: () => import("../views/user/MyOrders.vue")
      }
    ]
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash, behavior: "smooth", offset: { x: 0, y: 120 } };
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const isAdminRoute = /^\/admin/.test(to.path);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth) || isAdminRoute;
  const tokenSaved = localStorage.getItem("token");

  // Kayıtlı ve geçerli bir token varsa otomatik giriş yap
  //
  if (tokenSaved) {
    store.commit("auth/saveTokenData", {
      token: tokenSaved
    });

    const isTokenValid = store.getters["auth/isTokenValid"];

    if (isTokenValid) {
      store.commit("auth/setLoginStatus", true);
      // Get shopping cart
      store.dispatch("cart/getCart");
    } else {
      localStorage.removeItem("token");
    }
  }

  // Eğer yetkilendirme gerekli ise
  if (requiresAuth) {
    if (canUserAccess(to)) {
      next();
    } else {
      next({
        name: "Login",
        query: { returnUrl: to.path }
      });
    }
  } else {
    next();
  }
});

const canUserAccess = function (to: Route) {
  const isAdminRoute = /^\/admin/.test(to.path);
  const isUserLoggedIn = store.getters["auth/isUserLoggedIn"];
  const authData = store.getters["auth/data"];
  const authRole = to.meta.authRole;
  const isAdmin = isUserLoggedIn ? authData.roles.indexOf("Admin") >= 0 : false;
  const isTokenValid = store.getters["auth/isTokenValid"];

  // Token geçerli mi kontol et
  if (!isTokenValid) {
    return false;
  }

  // Eğer Route /admin içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isAdminRoute) {
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  // Kullanıcı oturum açmış mı?
  if (isUserLoggedIn) {
    // Route için bir rol belirlenmiş ise izin ver
    // Yoksa kullanıcının rolünü kontrol et
    if (!authRole) {
      return true;
    } else {
      // Admin ise izin ver
      if (isAdmin) {
        return true;
      }
      // Kullanıcı istenilen yetki rolünde mi?
      else {
        return authData.roles.indexOf(to.meta.authRole.toLowerCase()) >= 0;
      }
    }
  } else {
    return false;
  }
};

// router.beforeEach(async (to, from, next) => {
//   const isAdminRoute = /^\/admin/.test(to.path);
//   const requiresAuth = to.matched.some((record) => record.meta.requiresAuth) || isAdminRoute;
//   const tokenSaved = localStorage.getItem("token");

//   // Kayıtlı ve geçerli bir token varsa otomatik giriş yap
//   //
//   if (tokenSaved) {
//     store.commit("auth/saveTokenData", {
//       token: tokenSaved
//     });

//     const isTokenValid = store.getters["auth/isTokenValid"];

//     if (isTokenValid) {
//       store.commit("auth/setLoginStatus", true);
//       // Get shopping cart
//       store.dispatch("cart/getCart");
//     } else {
//       localStorage.removeItem("token");
//     }
//   }

//   // Eğer yetkilendirme gerekli ise
//   if (requiresAuth) {
//     if (canUserAccess(to, isAdminRoute)) {
//       next();
//     } else {
//       next({
//         name: "Login",
//         query: { returnUrl: to.path }
//       });
//     }
//   } else {
//     next();
//   }
// });

// const canUserAccess = function (to: Route, isAdminRoute: boolean) {
//   const isUserLoggedIn = store.getters["auth/isUserLoggedIn"];
//   const authData = store.getters["auth/data"];
//   const authRole = to.meta.authRole;
//   const isAdmin = isUserLoggedIn ? authData.roles.indexOf("Admin") >= 0 : false;
//   const isTokenValid = store.getters["auth/isTokenValid"];

//   // Token geçerli mi kontol et
//   if (!isTokenValid) {
//     return false;
//   }

//   // Eğer Route /admin içindeyse authRole'a bakmadan
//   // kullanıcının admin rolünde olup olmadığını sorgula
//   if (isAdminRoute) {
//     if (isAdmin) {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   // Kullanıcı oturum açmış mı?
//   if (isUserLoggedIn) {
//     // Route için bir rol belirlenmiş ise izin ver
//     // Yoksa kullanıcının rolünü kontrol et
//     if (!authRole) {
//       return true;
//     } else {
//       // Admin ise izin ver
//       if (isAdmin) {
//         return true;
//       }
//       // Kullanıcı istenilen yetki rolünde mi?
//       else {
//         return authData.roles.indexOf(to.meta.authRole.toLowerCase()) >= 0;
//       }
//     }
//   } else {
//     return false;
//   }
// };

export default router;
